body {
  @apply bg-white;
}
section {
  @apply mb-10;
}

hr {
  @apply my-6 border-t-2 border-gray-200;
}

a, a:visited, a:hover {
  @apply text-i8-red;
}

p {
  @apply text-i8-gray text-lg pb-2;
}

h1 {
  @apply text-5xl text-i8-red font-bold pt-8 pb-4;
}

h2 {
  @apply text-3xl text-i8-red font-bold pb-2 pt-4;
}

.gradient {
  // background: linear-gradient(90deg, #444 0%, #000 100%);
  background: linear-gradient(90deg, #000 0%, #444 100%);
}

.border-bottom-green {
  @apply border-b-8 border-i8-green;
}

.border-bottom-red {
  @apply border-b-2 border-i8-red;
}

.border-bottom-gray {
  @apply border-b-2 border-gray-200;
}
.border-bottom-white {
  @apply border-b-2 border-white;
}

.current-text-and-link {
  @apply text-current hover:text-current visited:text-current;
}

#nav {
  &.normal {
    @apply bg-transparent border-bottom-white;
    #menu {
      @apply bg-transparent;
    }
    #menuButton {
      @apply text-white;
    }
    #logo_gray, #logo_red {
      fill: white;
      stroke: white;
    }
  }
  &.inverted {
    @apply bg-white border-bottom-red;
    #menu {
      @apply bg-white;
    }
    #menuButton {
      @apply text-i8-red;
    }
  }
}

.nav-link {
  @apply w-full sm:mb-0 mb-2 py-1 px-4 inline-block text-center;
  &.normal {
    @apply no-underline text-lg text-white visited:text-white hover:bg-i8-green rounded;
  }
  &.inverted {
    @apply no-underline text-lg hover:text-white hover:bg-i8-red hover:ring-red rounded;
  }
  &.ringed {
    // @apply ring-0 ring-current sm:ring-0;
    @apply ring-1 ring-current sm:ring-0;
  }
}